import { useEffect, useMemo, useState, memo } from "react";

import { Button } from "primereact/button";
import { ButtonGroup } from "primereact/buttongroup";

import { Icon } from "utils/ui";

const MemoButton = memo(Button);

export const ZoomController = () => {
  const [zoom, setZoom] = useState<number>(100);
  const editorScale: string | null = document.documentElement.style.getPropertyValue("--editor-scale");

  const initZoom = useMemo(() => editorScale || "1", [editorScale]);

  useEffect(() => setZoom(Number(initZoom) * 100), [initZoom]);

  const onChange = (change: number) => {
    const z = Math.max(50, Math.min(100, zoom + change));
    setZoom(z);
    document.documentElement.style.setProperty("--editor-scale", `${z / 100}`);
  };

  return (
    <ButtonGroup>
      <MemoButton
        className="p-3 p-button-info"
        outlined
        title="Zoom out"
        onClick={() => onChange(-10)}
        children={<Icon name="ZoomOut" size={16} />}
      />

      <MemoButton
        label={`${zoom}%`}
        className="p-button-info"
        outlined
        style={{
          minWidth: "60px",
          cursor: "default",
          pointerEvents: "none",
          userSelect: "none",
          msUserSelect: "none",
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          padding: "0",
          height: "39px",
        }}
      />

      <MemoButton
        title="Zoom in"
        className="p-3 p-button-info"
        outlined
        onClick={() => onChange(10)}
        children={<Icon name="ZoomIn" size={16} />}
      />

      <MemoButton
        title="Reset zoom"
        className="p-3 p-button-info"
        outlined
        onClick={() => onChange(100)}
        children={<Icon name="Fullscreen" size={16} />}
      />
    </ButtonGroup>
  );
};

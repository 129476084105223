import "./page-layout.scss";
import React from "react";

import { kToolbarHeight } from "models";

interface _LayoutWrapperProps {
  menu: React.ReactNode;
  preview: React.ReactNode;
}

// https://www.adobe.com/uk/creativecloud/design/discover/a4-format.html

export const LayoutWrapper = ({ menu, preview }: _LayoutWrapperProps) => {
  return (
    <div className="layout-wrapper flex flex-row" style={{ height: `calc(100vh - ${kToolbarHeight}px)` }}>
      <div className="p-4 flex-1 overflow-auto classy-scrollbar">
        <div className="flex justify-center">
          <div style={{ minWidth: "600px" }} className="large-preview">
            <div className="a4page">
              <div className="dfl-header flex flex-col">
                <div className="dfl-header-skeleton"></div>
                <div className="dfl-header-skeleton"></div>
                {preview}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="block-side-menu-wrapper" style={{ width: "360px" }}>
        {menu}
      </div>
    </div>
  );
};

export default LayoutWrapper;

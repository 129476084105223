import "./side-menu-controls.scss";
import { useEffect, useMemo, useState } from "react";
import { Editor } from "@tiptap/react";

import { TabView, TabPanel } from "primereact/tabview";

import { SidebarTextMenu } from "../menus";
import { useTextmenuContentTypes } from "./hooks/useSidebarEditorState";

import { SidebarImageBlockMenu } from "../menus/ImageBlockMenu";
import { SidebarHorizontalLineMenu } from "../menus/HorizontalLineBlockMenu";
import { SidebarSpacerBlockMenu } from "../menus/SpacerBlockMenu";
import { SidebarMenuFooter } from "./components/SideMenuFooter";
import { FieldTokenMenu } from "../menus/FieldTokenMenu/FieldTokenMenu";

export const SideMenuControls = ({ editor }: { editor: Editor }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { isImageBlock, isHorizontalLineBlock, isSpacerBlock } = useTextmenuContentTypes(editor);

  const isTextBlock = useMemo(() => {
    return !isImageBlock && !isHorizontalLineBlock && !isSpacerBlock;
  }, [isImageBlock, isHorizontalLineBlock, isSpacerBlock]);

  useEffect(() => {
    if (isTextBlock) {
      setActiveIndex(0);
    } else if (isImageBlock) {
      setActiveIndex(2);
    } else if (isHorizontalLineBlock) {
      setActiveIndex(3);
    } else if (isSpacerBlock) {
      setActiveIndex(4);
    } else {
      setActiveIndex(0);
    }
  }, [isTextBlock, isImageBlock, isHorizontalLineBlock, isSpacerBlock]);

  const tabPanelHeader = (options: any) => {
    return (
      <div className={"pt-1 text-center"} style={{ height: "58px" }} onClick={options.onClick}>
        <h2 style={{ fontSize: "1.1rem" }}>{options.titleElement?.props?.children}</h2>
      </div>
    );
  };

  return (
    <div className="block-side-menu-wrapper" style={{ width: "360px" }}>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Text" headerTemplate={tabPanelHeader} style={{ display: !isTextBlock ? "none" : undefined }}>
          <SidebarTextMenu editor={editor} />
        </TabPanel>

        <TabPanel
          header="Fields"
          headerTemplate={tabPanelHeader}
          style={{ display: !isTextBlock ? "none" : undefined }}
        >
          <FieldTokenMenu editor={editor} />
        </TabPanel>

        <TabPanel
          header="Image"
          headerTemplate={tabPanelHeader}
          style={{ display: !isImageBlock ? "none" : undefined }}
        >
          <SidebarImageBlockMenu editor={editor} />
        </TabPanel>

        <TabPanel
          header="Line"
          headerTemplate={tabPanelHeader}
          style={{ display: !isHorizontalLineBlock ? "none" : undefined }}
        >
          <SidebarHorizontalLineMenu editor={editor} />
        </TabPanel>

        <TabPanel
          header="Spacer"
          headerTemplate={tabPanelHeader}
          style={{ display: !isSpacerBlock ? "none" : undefined }}
        >
          <SidebarSpacerBlockMenu editor={editor} />
        </TabPanel>
      </TabView>
      <SidebarMenuFooter editor={editor} />
    </div>
  );
};

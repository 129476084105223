import { Editor, useEditorState } from "@tiptap/react";
import { memo, useCallback, useMemo } from "react";
import deepEql from "fast-deep-equal";

import { ButtonGroup } from "primereact/buttongroup";

import { ImageBlockTypes } from "pages/tip-tap-editor/extensions/ImageBlock";

import { useAppSelector } from "utils/store";
import { SidebarMenu, Icon } from "utils/ui";
import { ImagePicker } from "../../panels";

const MemoButton = memo(SidebarMenu.Button);

export const SidebarImageBlockMenu = ({ editor }: { editor: Editor }) => {
  const logoUrl = useAppSelector((state) => state.templateBuilderSlice.logoUrl);
  const { isImageCenter, isImageLeft, isImageRight, width, type, src, documentId } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isImageLeft: ctx.editor.isActive("imageBlock", { align: "left" }),
        isImageCenter: ctx.editor.isActive("imageBlock", { align: "center" }),
        isImageRight: ctx.editor.isActive("imageBlock", { align: "right" }),
        width: parseInt(ctx.editor.getAttributes("imageBlock")?.width || 0),
        type: ctx.editor.getAttributes("imageBlock")?.type,
        src: ctx.editor.getAttributes("imageBlock")?.src,
        documentId: ctx.editor.getAttributes("imageBlock")?.documentId,
      };
    },
    equalityFn: deepEql,
  });

  const getTitle = useMemo(() => {
    switch (type) {
      case ImageBlockTypes.LOGO:
        return "Company Logo";
      case ImageBlockTypes.PROJECT_IMAGE:
        return "Project Image";
      case ImageBlockTypes.IMAGE:
      default:
        return "Image";
    }
  }, [type]);

  const imgSrc = useMemo(() => {
    if (type === ImageBlockTypes.LOGO) {
      return logoUrl ?? src;
    }
    return src;
  }, [logoUrl, src, type]);

  const onAlignImageLeft = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign("left").run();
  }, [editor]);

  const onAlignImageCenter = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign("center").run();
  }, [editor]);

  const onAlignImageRight = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign("right").run();
  }, [editor]);

  const onWidthChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockWidth(value).run();
    },
    [editor]
  );

  const onSourceChange = useCallback(
    (src?: string, documentId?: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setImageSource(src, documentId).run();
    },
    [editor]
  );

  const onDelete = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).deleteSelection().run();
  }, [editor]);

  const isGenericImage = useMemo(() => type === ImageBlockTypes.IMAGE, [type]);

  return (
    <SidebarMenu.Wrapper>
      <SidebarMenu.Heading title={getTitle} />

      {type === ImageBlockTypes.PROJECT_IMAGE && (
        <p className="text-sm">Images uploaded against your project settings will be insterted here.</p>
      )}

      {type === ImageBlockTypes.LOGO && <p className="text-sm">Upload your logo from the company settings page.</p>}

      {isGenericImage && <ImagePicker imgSrc={imgSrc} documentId={documentId} onSourceChange={onSourceChange} />}

      <SidebarMenu.Heading title="Alignment" />
      <ButtonGroup>
        <MemoButton tooltip="Align left" active={isImageLeft} onClick={onAlignImageLeft}>
          <Icon name="AlignHorizontalDistributeStart" />
        </MemoButton>
        <MemoButton tooltip="Align center" active={isImageCenter} onClick={onAlignImageCenter}>
          <Icon name="AlignHorizontalDistributeCenter" />
        </MemoButton>
        <MemoButton tooltip="Align right" active={isImageRight} onClick={onAlignImageRight}>
          <Icon name="AlignHorizontalDistributeEnd" />
        </MemoButton>
      </ButtonGroup>

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Size" value={`${width} %`} />
      <SidebarMenu.SliderInput onChange={onWidthChange} value={width} minValue={10} maxValue={100} />

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Remove" />
      <MemoButton tooltip="Delete image" onClick={onDelete} children={<Icon name="Trash2" />} />
    </SidebarMenu.Wrapper>
  );
};

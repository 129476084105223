import { Editor } from "@tiptap/react";

import { Figcaption, HorizontalLineBlock, ImageBlock, Link, PageBreakBlock, SpacerBlock } from "../extensions";

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !["TD", "TH"].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn = container && container.querySelector && container.querySelector("a.grip-column.selected");
  const gripRow = container && container.querySelector && container.querySelector("a.grip-row.selected");

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

/**
 * Checks if any of the selected nodes are custom nodes
 *
 * Used for managing the `TextMenu` state
 *
 * @param editor
 * @param node
 * @returns
 */
export const isCustomNodeSelected = (editor: Editor, node: HTMLElement): boolean => {
  const customNodes = [
    ImageBlock.name,
    ImageBlock.name,
    Link.name,
    Figcaption.name,
    HorizontalLineBlock.name,
    SpacerBlock.name,
    PageBreakBlock.name,
  ];

  return customNodes.some((type) => editor.isActive(type)) || isTableGripSelected(node);
};

export default isCustomNodeSelected;

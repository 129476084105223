import { cn } from "utils/helpers";
import { icons } from "lucide-react";
import { memo } from "react";

export type IconProps = {
  name: keyof typeof icons;
  className?: string;
  strokeWidth?: number;
  size?: number;
};

export const Icon = memo(({ name, className, strokeWidth, size }: IconProps) => {
  const IconComponent = icons[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent className={cn(size ? "" : "w-4 h-4", className)} strokeWidth={strokeWidth || 2.5} size={size} width={size} />
  );
});

Icon.displayName = "Icon";

import "./template-builder.scss";
import { useEffect, useRef } from "react";
import { shallowEqual } from "react-redux";

import { ConfirmDialog } from "primereact/confirmdialog";

import { TemplatePageType, kToolbarHeight } from "models";
import { useAppSelector, useAppDispatch, loadTemplate, setLoading } from "utils/store";
import { LoadingController } from "utils/ui";
import { getTemplate } from "utils/api";


import DefectListTemplate from "./components/defect-list-template/defect-list-template";
import { CustomPageTemplate } from "pages/CustomPageTemplate";
import { TemplateToolbar } from "./components/template-toolbar";
import { GeneralSettings } from "./components/general-settings";
import { PageNavigation } from "./components/page-navigation";

// https://atlassian.design/components/pragmatic-drag-and-drop/core-package
// https://atlassian.design/components/pragmatic-drag-and-drop/examples/#simple-list-on-other-stacks
// Working example https://github.com/alexreardon/pdnd-react-tailwind/blob/main/src/list.tsx

export function TemplateBuilder() {
  const dispatch = useAppDispatch();
  const showSettings = useAppSelector((state) => state.templateBuilderSlice.showSettings);
  const loading = useAppSelector((state) => state.templateBuilderSlice.loading);
  const selectedPageIndex = useAppSelector((state) => state.templateBuilderSlice.selectedPageIndex);
  const pageType = useAppSelector((state) => state.templateBuilderSlice.pages[selectedPageIndex]?.type, shallowEqual);
  const pageId = useAppSelector((state) => state.templateBuilderSlice.pages[selectedPageIndex]?.id, shallowEqual);

  const isMounted = useRef(false);

  useEffect(() => {
    async function loadData() {
      const result = await getTemplate();
      if (result) {
        dispatch(loadTemplate(result));
      }
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 1000);
    }

    if (!isMounted.current) loadData();
    return () => {
      isMounted.current = true;
    };
  });

  return (
    <div className="overflow-hidden surface-ground" style={{ height: "100vh", width: "100vw" }}>
      <TemplateToolbar />

      <div className="flex flex-row" style={{ height: `calc(100vh - ${kToolbarHeight}px)` }}>
        <PageNavigation />

        {/* <!-- COLUMN TWO --> */}
        {showSettings && (
          <div className="flex-1">
            <GeneralSettings key={pageId} />
          </div>
        )}
        {!showSettings && (
          <div className="flex-1">
            {pageType === TemplatePageType.coverPage && <CustomPageTemplate key={pageId} />}
            {pageType === TemplatePageType.defectList && <DefectListTemplate />}
            {pageType === TemplatePageType.customPage && <CustomPageTemplate key={pageId} />}
            {pageType === undefined && (
              <div className="p-4">
                <h1>No Page Selected</h1>
              </div>
            )}
          </div>
        )}
      </div>

      <LoadingController loading={loading} />

      <ConfirmDialog />
    </div>
  );
}

import * as Sentry from "@sentry/react";
import Alert from "./alert.service";

const handleException = (error: any, { hint, showAlert, alertCallbackFn }: _HandleExceptionProps) => {
  console.error(error);

  try {
    Sentry.captureException(error, { data: hint });
  } catch (ex) {
    console.error(ex);
  }

  if (showAlert) {
    Alert.simpleAlert({
      header: "Unknown Exception",
      message: `An unknown error occurred, message: ${error}`,
      callbackFn: () => {
        if (alertCallbackFn) alertCallbackFn();
      },
    });
  }
};

interface _HandleExceptionProps {
  hint: any;
  showAlert?: boolean;
  alertCallbackFn?: () => void;
}

const Log = {
  handleException,
};

export default Log;

import queryString from "query-string";
import axios from "axios";

import { API_ENDPOINT } from "utils/config";
import { Alert, Log } from "utils/services";

export const getPreview = async (): Promise<{ buffer: ArrayBuffer | undefined }> => {
  let params = queryString.parse(window.location.search);
  const tenantId = params.tid ?? "";
  const templateId = params.rid ?? "";
  const userId = params.uid ?? "";

  try {
    const request = await axios.post<{ buffer: ArrayBuffer | undefined }>(
      API_ENDPOINT.GET_PREVIEW,
      {
        tenantId,
        templateId,
        userId,
      },
      {
        responseType: "arraybuffer",
      }
    );

    if (request.status === 200 && request.data) {
      return {
        buffer: request.data as any,
      };
    } else {
      Alert.simpleAlert({
        header: `Error: ${request.status}`,
        message: `An unknown error occurred`,
      });
    }
  } catch (ex) {
    Log.handleException(ex, {
      hint: {
        endpoint: "getPreview()",
        params: { tenantId, templateId },
      },
      showAlert: true,
    });
  }

  return { buffer: undefined };
};

import { memo, useCallback, useEffect, useState } from "react";
import { Icon } from "../../../../../../utils/ui/Icon";

export type ImageBlockWidthProps = {
  onChange: (value: number) => void;
  value: number;
};

export const HorizontalLineBlockWidth = memo(({ onChange, value }: ImageBlockWidthProps) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        let nextValue = parseInt(e.target.value);

        if (nextValue > 100) return;
        onChange(nextValue);
        setCurrentValue(nextValue);
      } catch (ex) {
        //
      }
    },
    [onChange]
  );

  return (
    <div className="flex items-center gap-2 pl-2 pr-2">
      <div className="text-neutral-500">
        <Icon name="UnfoldHorizontal" />
      </div>
      <input
        className="h-2 bg-neutral-200 border-0 rounded appearance-none fill-neutral-300"
        type="range"
        min="10"
        max="101"
        step="1"
        onChange={handleChange}
        value={currentValue}
      />
      <span className="text-xs font-semibold text-neutral-500 select-none">{value}%</span>
    </div>
  );
});

HorizontalLineBlockWidth.displayName = "HorizontalLineBlockWidth";

import { mergeAttributes, Node, NodeViewProps } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";

import "./SpacerBlock.scss";
// https://tiptap.dev/docs/editor/extensions/custom-extensions/node-views/react

interface SpacerBlockOptions {
  HTMLAttributes: Record<string, any>;
  color: string;
  height: number;
  width: number;
  alignment: "left" | "center" | "right";
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    spacerBlock: {
      setSpacerBlock: () => ReturnType;
      setSpacerBlockHeight: (height: number) => ReturnType;
    };
  }
}

const SpacerComponent = ({ node }: NodeViewProps) => {
  const { height } = node.attrs;

  return (
    <NodeViewWrapper className="horizontal-line-block">
      <div className="w-full flex items-center" style={{ minHeight: "22px" }}>
        <div style={{ height: `calc(${height}px * var(--editor-scale)`, width: "100%" }} />
      </div>
    </NodeViewWrapper>
  );
};

export const SpacerBlock = Node.create<SpacerBlockOptions>({
  name: "spacerBlock",

  group: "block",

  content: "",

  selectable: true,

  // this for, @Mentions, project photo, not text stuff
  atom: true,

  addAttributes() {
    return {
      height: {
        default: 50,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SpacerComponent);
  },

  addCommands() {
    return {
      setSpacerBlock:
        () =>
        ({ commands }) => {
          return commands.insertContent({ type: "spacerBlock" });
        },
      setSpacerBlockHeight:
        (height) =>
        ({ commands }) => {
          return commands.updateAttributes("spacerBlock", { height });
        },
    };
  },
});

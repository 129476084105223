import "./image-picker.scss";
import { useRef, useState } from "react";
import { Image, Trash } from "lucide-react";

import { FileUpload, FileUploadHandlerEvent, FileUploadOptions } from "primereact/fileupload";
import { Button } from "primereact/button";

import { cn } from "utils/helpers";
import { deletePhoto, uploadPhoto } from "utils/api";
import { Spinner } from "utils/ui/Spinner";
import { useAppSelector } from "utils/store";
import { Images } from "utils/services";
import { environment } from "environments/environment";

export const ImagePicker = ({
  imgSrc,
  documentId,
  onSourceChange,
}: {
  imgSrc?: string;
  documentId?: number;
  onSourceChange: (src?: string, docId?: number) => void;
}) => {
  const storageToken = useAppSelector((state) => state.templateBuilderSlice.storageToken);

  const [isLoading, setIsLoading] = useState(false);
  const fileUploadRef = useRef<FileUpload>(null);

  const customBase64Uploader = async (event: FileUploadHandlerEvent) => {
    if (!event.files.length) return;

    const file: File = event.files[0];

    setIsLoading(true);

    const resizedImage = await Images.createResizedFile(file, 1024);

    if (!environment.production) {
      console.log(`File size reduced: ${file.size} > ${resizedImage.size}`);
    }

    const result = await uploadPhoto(resizedImage);

    if (result) {
      onSourceChange(result.url, result.documentId);
    } else {
      fileUploadRef.current?.clear();
    }

    setIsLoading(false);
  };

  const onDeleteImage = async (): Promise<void> => {
    if (documentId) {
      const result = await deletePhoto(documentId);
      if (result) onSourceChange();
    } else {
      onSourceChange();
    }
  };

  const headerTemplate = ({ className, chooseButton }: any) => {
    return <div className={cn(className)}>{chooseButton}</div>;
  };

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="p-6 flex flex-col items-center justify-center" style={{ color: "var(--surface-600)" }}>
        <div>
          {!isLoading && <Image size={64} strokeWidth={1} />}
          {isLoading && <Spinner style={{ height: 48, width: 48, margin: "8px" }} />}
        </div>
        <p>Drag and drop file to upload.</p>
      </div>
    );
  };

  const chooseOptions: FileUploadOptions = {
    className: "custom-choose-btn",
  };

  return (
    <div className="image-picker-wrapper">
      {imgSrc && (
        <div className="p-2 img-wrapper flex items-center justify-center">
          <div className="image-delete-btn">
            <Button
              title="Remove image"
              size="small"
              className="p-button-rounded p-button-danger p-0"
              onClick={onDeleteImage}
            >
              <Trash size={22} />
            </Button>
          </div>
          <img src={imgSrc ? `${imgSrc}?${storageToken}` : ""} alt="" />
        </div>
      )}

      {!imgSrc && (
        <FileUpload
          ref={fileUploadRef}
          name="demo[]"
          multiple={false}
          auto={true}
          accept="image/*"
          customUpload
          uploadHandler={customBase64Uploader}
          headerTemplate={headerTemplate}
          itemTemplate={itemTemplate}
          emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions}
        />
      )}
    </div>
  );
};

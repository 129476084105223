import "./page-navigation.scss";
import { useEffect } from "react";

import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { flushSync } from "react-dom";

import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

import { GrabbableButton } from "./components";

import { TemplatePageType, isTemplatePageData, kToolbarHeight } from "models";

import {
  useAppSelector,
  useAppDispatch,
  selectPage,
  deletePage,
  reorderPage,
  addPage,
  selectSettingsPage,
  movePage,
} from "utils/store";
import { Icon } from "utils/ui";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";
import { saveTemplate } from "utils/api";

export const PageNavigation = () => {
  const pagesState = useAppSelector((state) => state.templateBuilderSlice.pages);
  const selectedPageIndex = useAppSelector((state) => state.templateBuilderSlice.selectedPageIndex);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isTemplatePageData(source.data);
      },
      onDrop({ location, source }) {
        const pages = [...pagesState];

        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;

        if (!isTemplatePageData(sourceData) || !isTemplatePageData(targetData)) {
          return;
        }

        const indexOfSource = pages.findIndex((o) => o.id === sourceData.taskId);
        const indexOfTarget = pages.findIndex((o) => o.id === targetData.taskId);

        if (indexOfTarget < 0 || indexOfSource < 0) {
          return;
        }

        const closestEdgeOfTarget = extractClosestEdge(targetData);

        const reorderedList = reorderWithEdge({
          list: pages,
          startIndex: indexOfSource,
          indexOfTarget,
          closestEdgeOfTarget,
          axis: "vertical",
        });

        // Validate contents page is before defect list page
        const indexOfCover = reorderedList.findIndex((o) => o.type === TemplatePageType.coverPage);
        const indexOfContents = reorderedList.findIndex((o) => o.type === TemplatePageType.contentsPage);
        const indexOfDefectList = reorderedList.findIndex((o) => o.type === TemplatePageType.defectList);

        if (indexOfCover !== 0 || indexOfContents > indexOfDefectList) return;

        // bug case where dragging on top of cover pages sets this to 0
        const selectedPageIndex = indexOfTarget > 0 ? indexOfTarget : 1;

        // Using `flushSync` so we can query the DOM straight after this line
        flushSync(() => {
          dispatch(reorderPage({ pages: reorderedList, selectedPageIndex }));
        });
        // Being simple and just querying for the task after the drop.
        // We could use react context to register the element in a lookup,
        // and then we could retrieve that element after the drop and use
        // `triggerPostMoveFlash`. But this gets the job done.
        const element = document.querySelector(`[data-task-id="${sourceData.taskId}"]`);
        if (element instanceof HTMLElement) {
          triggerPostMoveFlash(element);
        }
      },
    });
  }, [pagesState, dispatch]);

  return (
    <div
      className="overflow-y-auto classy-scrollbar"
      style={{
        paddingBottom: "40px",
        width: "var(--sidebar-width)",
        height: `calc(100vh - ${kToolbarHeight}px)`,
      }}
    >
      <div className="flex flex-col items-center p-2" style={{ maxWidth: "5rem" }}>
        {pagesState.map((page, i) => (
          <GrabbableButton
            key={page.id}
            pageElement={page}
            selected={i === selectedPageIndex}
            onPageSelect={() => dispatch(selectPage(i))}
            onAddPage={() => dispatch(addPage(i))}
            onPageDelete={(event: any) => {
              confirmPopup({
                target: event.currentTarget,
                message: "Do you want to delete this section?",
                icon: "pi pi-info-circle",
                defaultFocus: "reject",
                acceptClassName: "p-button-danger",
                accept: () => {
                  dispatch(deletePage(i));
                  dispatch(saveTemplate());
                },
              });
            }}
            onMovePage={(direction) => dispatch(movePage({ from: i, to: i + direction }))}
          />
        ))}

        <Button
          title="Add a Custom Section"
          onClick={() => {
            dispatch(addPage(pagesState.length - 1));
            dispatch(saveTemplate());
          }}
          className="small-menu-btn"
          severity="info"
        >
          <div className="small-btn-content">
            <Icon name="Plus" size={20} strokeWidth={2} />
            <span className="small-btn-text">Add Section</span>
          </div>
        </Button>

        <Divider className="mt-1 mb-1" />

        <Button
          title="General Settings"
          onClick={() => dispatch(selectSettingsPage())}
          className="small-menu-btn"
          severity="info"
        >
          <div className="small-btn-content">
            <Icon name="Settings" size={20} strokeWidth={2} />
            <span className="small-btn-text">General Settings</span>
          </div>
        </Button>
      </div>

      <ConfirmPopup />
    </div>
  );
};

import { memo } from "react";
import { BubbleMenu, Editor } from "@tiptap/react";

import { Icon, Toolbar } from "utils/ui";
import { useTextmenuCommands } from "./hooks/useTextmenuCommands";
import { useTextmenuStates } from "./hooks/useTextmenuStates";

import { EditLinkPopover } from "./components/EditLinkPopover";

// We memorize the button so each button is not rerendered
// on every editor state change
const MemoButton = memo(Toolbar.Button);

interface TextMenuProps {
  editor: Editor;
}

export const TextMenu = ({ editor }: TextMenuProps) => {
  const states = useTextmenuStates(editor);

  return (
    <BubbleMenu
      tippyOptions={{
        popperOptions: {
          placement: "top-start",
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
                padding: 8,
              },
            },
            {
              name: "flip",
              options: {
                fallbackPlacements: ["bottom-start", "top-end", "bottom-end"],
              },
            },
          ],
        },
        // appendTo: documentBodyRef,
        maxWidth: "calc(100vw - 16px)",
      }}
      editor={editor}
      pluginKey="simpleTextMenu"
      shouldShow={states.shouldShow}
      updateDelay={100}
    >
      <EditorTextMenu editor={editor} />
    </BubbleMenu>
  );
};

export const EditorTextMenu = ({ editor }: { editor: Editor }) => {
  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);
  // const blockOptions = useTextmenuContentTypes(editor);

  return (
    <Toolbar.Wrapper>
      {/* <Toolbar.Divider /> */}
      {/* <MemoContentTypePicker options={blockOptions} /> */}
      {/* <MemoFontSizePicker onChange={commands.onSetFontSize} value={states.currentSize || ""} /> */}
      {/* <Toolbar.Divider /> */}
      <MemoButton tooltip="Bold" tooltipShortcut={["Mod", "B"]} onClick={commands.onBold} active={states.isBold}>
        <Icon name="Bold" />
      </MemoButton>

      <MemoButton tooltip="Italic" tooltipShortcut={["Mod", "I"]} onClick={commands.onItalic} active={states.isItalic}>
        <Icon name="Italic" />
      </MemoButton>

      <MemoButton
        tooltip="Underline"
        tooltipShortcut={["Mod", "U"]}
        onClick={commands.onUnderline}
        active={states.isUnderline}
      >
        <Icon name="Underline" />
      </MemoButton>

      <Toolbar.Divider />

      {/* <MemoButton
        tooltip="Align left"
        tooltipShortcut={["Shift", "Mod", "L"]}
        onClick={commands.onAlignLeft}
        active={states.isAlignLeft}
      >
        <Icon name="AlignLeft" />
      </MemoButton> */}
      {/* <MemoButton
        tooltip="Align center"
        tooltipShortcut={["Shift", "Mod", "E"]}
        onClick={commands.onAlignCenter}
        active={states.isAlignCenter}
      >
        <Icon name="AlignCenter" />
      </MemoButton> */}
      {/* <MemoButton
        tooltip="Align right"
        tooltipShortcut={["Shift", "Mod", "R"]}
        onClick={commands.onAlignRight}
        active={states.isAlignRight}
      >
        <Icon name="AlignRight" />
      </MemoButton> */}
      {/* <MemoButton
    tooltip="Justify"
    tooltipShortcut={["Shift", "Mod", "J"]}
    onClick={commands.onAlignJustify}
    active={states.isAlignJustify}
  >
    <Icon name="AlignJustify" />
  </MemoButton> */}

      <MemoButton
        tooltip="Bullet List"
        onClick={commands.toggleBulletList}
        active={states.isBulletList}
        children={<Icon name="List" />}
      />

      <MemoButton
        tooltip="Numbered List"
        onClick={commands.toggleOrderedList}
        active={states.isOrderedList}
        children={<Icon name="ListOrdered" />}
      />

      <Toolbar.Divider />

      <EditLinkPopover onSetLink={commands.onLink} />

      {/* <Popover.Root>
        <Popover.Trigger asChild>
          <MemoButton active={!!states.currentColor} tooltip="Text color">
            <Icon name="Palette" />
          </MemoButton>
        </Popover.Trigger>
        <Popover.Content side="top" sideOffset={8} asChild>
          <Surface className="p-1">
            <MemoColorPicker
              color={states.currentColor}
              onChange={commands.onChangeColor}
              onClear={commands.onClearColor}
            />
          </Surface>
        </Popover.Content>
      </Popover.Root> */}
    </Toolbar.Wrapper>
  );
};

import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import queryString from "query-string";
import setSession from "utils/helpers/session";

import { PrimeReactProvider } from "primereact/api";

import { store } from "utils/store";
import { Provider } from "react-redux";

import { TemplateBuilder, PageNotFound, PdfPreview } from "./pages";

function App() {
  const params = queryString.parse(window.location.search);
  const tenantId = params.tid ?? "";
  const templateId = params.rid ?? "";
  const token = params.token ?? "";
  setSession(params);

  // initially set the editor scale
  document.documentElement.style.setProperty("--editor-scale", "1");

  return (
    <BrowserRouter>
      <Routes>
        {tenantId && templateId && token && (
          <Route
            path="editor"
            element={
              <Provider store={store}>
                <PrimeReactProvider>
                  <TemplateBuilder />
                </PrimeReactProvider>
              </Provider>
            }
            errorElement={<PageNotFound />}
          ></Route>
        )}

        {tenantId && templateId && token && (
          <Route path="preview" element={<PdfPreview />} errorElement={<PageNotFound />}></Route>
        )}

        <Route path="*" element={<PageNotFound />} errorElement={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

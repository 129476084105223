import { useEditor } from "@tiptap/react";

import {
  CharacterCount,
  Color,
  Column,
  Columns,
  FieldToken,
  Placeholder,
  StarterKit,
  TextAlign,
  TextStyle,
  Typography,
  Underline,
  Document,
  Link,
} from "../extensions";

// https://tiptap.dev/docs/guides/performance
export const useMiniBlockEditor = ({ onChange }: { onChange: (json: any) => void }) => {
  const editor = useEditor(
    {
      immediatelyRender: true,
      shouldRerenderOnTransaction: false,
      autofocus: true,
      onCreate: (ctx) => {
        ctx.editor.commands.focus("start", { scrollIntoView: true });
      },
      // triggered on every change
      onUpdate: ({ editor }) => {
        const json = editor.getJSON();
        // send the content to an API here
        onChange(json);
      },
      extensions: [
        Document,
        Columns,
        Column,
        StarterKit.configure({
          document: false,
          dropcursor: false,
          heading: false,
          horizontalRule: false,
          blockquote: false,
          codeBlock: false,
          paragraph: {
            HTMLAttributes: {
              class: "report-paragraph",
            },
          },
        }),
        TextStyle,
        Color,
        Underline,
        CharacterCount.configure({ limit: 100 }),
        TextAlign.extend({
          addKeyboardShortcuts() {
            return {};
          },
        }).configure({
          types: ["heading", "paragraph"],
        }),
        Typography,
        Placeholder,
        FieldToken,
        Link.configure({
          openOnClick: false,
        }),
      ],
      editorProps: {
        attributes: {
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off",
          class: "",
        },
      },
      onContentError({ editor, error }) {
        // Your error handling logic here
        console.error(error);
      },
      // https://tiptap.dev/docs/guides/invalid-schema
      enableContentCheck: true,
      // This option gives us the control to enable the default behavior of rendering the editor immediately.
      // immediatelyRender: true,
      // This option gives us the control to disable the default behavior of re-rendering the editor on every transaction.
      // shouldRerenderOnTransaction: false,
    },
    []
  );

  return { editor };
};

import queryString from "query-string";
import axios from "axios";

import { API_ENDPOINT } from "utils/config";
import { Alert, Log } from "utils/services";
import { IApiResponse } from "models";

export const deletePhoto = async (photoId: number): Promise<boolean> => {
  let params = queryString.parse(window.location.search);
  const tenantId = params.tid ?? "";
  const templateId = params.rid ?? "";

  try {
    const request = await axios.delete<IApiResponse<void>>(API_ENDPOINT.PHOTO, {
      data: {
        imageType: "template",
        photoId,
      },
    });

    if (request.status === 200) {
      // OK!
      return true;
    } else {
      Alert.simpleAlert({
        header: `Error: ${request.status}`,
        message: `An unknown error occurred`,
      });
    }
  } catch (ex) {
    Log.handleException(ex, {
      hint: {
        endpoint: "deletePhoto()",
        params: { tenantId, templateId },
      },
      showAlert: true,
    });
  }

  return false;
};

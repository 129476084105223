import { forwardRef } from "react";
import "./drag-handle.scss";
import { Icon, Surface } from "utils/ui";

export const DragHandle = forwardRef<HTMLDivElement, DragHandleProps>((props, ref) => {
  const onClick = (event: any, direction: 1 | -1) => {
    props.onMovePage(direction);
    event.stopPropagation();
  };

  return (
    <div className="drag-handle-wrapper">
      <div className="drag-handle">
        <Surface className="h-full cursor-pointer">
          <button title="Move up" className="drag-handle-btn" onClick={(e) => onClick(e, -1)}>
            <Icon name="ChevronUp" />
          </button>
          <button title="Move down" className="drag-handle-btn cursor-pointer" onClick={(e) => onClick(e, 1)}>
            <Icon name="ChevronDown" />
          </button>
        </Surface>
      </div>
    </div>
  );
});

interface DragHandleProps {
  disabled: boolean;
  onMovePage: (direction: 1 | -1) => void;
}

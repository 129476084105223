import { mergeAttributes, Node, NodeViewProps } from "@tiptap/core";
import { NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";

// https://tiptap.dev/docs/editor/extensions/custom-extensions/node-views/react

export interface SignatureBlockOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    signatureBlock: {
      setSignatureBlock: () => ReturnType;
    };
  }
}

const SignatureBlockComponent = (_: NodeViewProps) => {
  return (
    <NodeViewWrapper className="signature-block">
      <div className="w-full block-signature-line">
        <div className="flex flex-row items-end" data-alert-type={"signature-block"}>
          <div className="signature-text">
            <NodeViewContent className="content" />
          </div>
          <div className="signature-pad flex-1"></div>
          <div className="flex flex-row items-end">
            <div className="signature-date flex-1"></div>
            <div>/</div>
            <div className="signature-date flex-1"></div>
            <div>/</div>
            <div className="signature-date flex-1"></div>
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export const SignatureBlock = Node.create<SignatureBlockOptions>({
  name: "signature",

  group: "block",

  content: "inline*",

  marks: "",

  selectable: true,

  // this for, @Mentions, project photo, not text stuff
  // atom: true,

  addAttributes() {
    return {};
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SignatureBlockComponent);
  },

  addCommands() {
    return {
      setSignatureBlock:
        () =>
        ({ commands }) => {
          return commands.insertContent({ type: "signature", content: [{ type: "text", text: "Prepared By" }] });
        },
    };
  },
});

import queryString from "query-string";
import axios from "axios";

import { API_ENDPOINT } from "utils/config";
import { Alert, Log } from "utils/services";
import { IApiResponse } from "models";

export const uploadPhoto = async (file: File): Promise<{ documentId: number; url: string } | undefined> => {
  let params = queryString.parse(window.location.search);
  const tenantId = params.tid ?? "";
  const userId = params.uid ?? "";
  const templateId = params.rid ?? "";

  const filename = file.name;

  try {
    const request = await axios.post<IApiResponse<TUploadPhoto[]>>(
      API_ENDPOINT.PHOTO,
      {
        userId: `${userId}`,
        tenantId: `${tenantId}`,
        defectId: null,
        imageType: "template",
        filename: filename,
        file: file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (request.status === 200 && request.data.data && request.data.data.length > 0) {
      // remove the storagage token
      const photoUrl = request.data.data[0].url;
      const urlPars = photoUrl.split(filename);
      return {
        documentId: request.data.data[0].cloudId,
        url: urlPars[0] + filename,
      };
    } else {
      Alert.simpleAlert({
        header: `Upload Error: ${request.status}`,
        message: `An unknown error occurred. ${request.data.message}`,
      });
    }
  } catch (ex) {
    Log.handleException(ex, {
      hint: {
        endpoint: "uploadPhoto()",
        params: { tenantId, templateId },
      },
      showAlert: true,
    });
  }

  return;
};

type TUploadPhoto = {
  cloudId: number;
  filename: string;
  success: boolean;
  url: string;
};

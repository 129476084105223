import { confirmDialog } from "primereact/confirmdialog";

/**
 * Show simple alert with no callbacks
 *
 * Requires a <ConfirmDialog /> to be added somewhere in the tree
 *
 * @param {string} header Header text of alert dialog
 * @param {string} message Main message content of the alert
 */
const simpleAlert = ({ header, message, callbackFn }: _SimpleAlertProps): void => {
  confirmDialog({
    header: header || "Error",
    message: message || "An unknown error occurred",
    acceptLabel: "OK",
    rejectClassName: "hide-this",
    className: "global-alert-class",
    closeIcon: false,
    closable: false,
    style: {
      maxWidth: "400px",
      whiteSpace: "pre-wrap",
    },
    accept: () => {
      if (callbackFn) callbackFn();
    },
  });
};

/**
 * Show simple alert with a callback function on accept
 *
 * Requires a <ConfirmDialog /> to be added somewhere in the tree
 *
 * @param {string} message Main message content of the alert
 * @param {string} header Header text of alert dialog
 * @param {string} acceptLabel Label for the accept button
 * @param {function} callbackFn Function to call on accept
 */
const simpleConfirm = ({ header, message, acceptLabel, callbackFn }: _SimpleConfirmProps): void => {
  confirmDialog({
    header: header,
    message: message,
    acceptLabel: acceptLabel,
    rejectLabel: "Cancel",
    className: "global-alert-class",
    style: { width: "50vw", maxWidth: "500px", minHeight: "300px" },
    accept: () => callbackFn(),
  });
};

interface _SimpleAlertProps {
  header: string;
  message: string;
  callbackFn?: () => void;
}

interface _SimpleConfirmProps {
  header: string;
  message: string;
  acceptLabel: string;
  callbackFn: () => void;
}

const Alert = {
  simpleAlert,
  simpleConfirm,
};

export default Alert;

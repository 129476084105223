import { ReactNodeViewRenderer } from "@tiptap/react";
import { mergeAttributes, Range } from "@tiptap/core";

import { ImageBlockView } from "../../components/menus/ImageBlockMenu/components/ImageBlockView";
import { Image as BaseImage } from "@tiptap/extension-image";

import { kImageLogoPlaceholder, kImageProjectPlaceholder } from "models";

export enum ImageBlockTypes {
  LOGO = "logo",
  PROJECT_IMAGE = "project-image",
  IMAGE = "image",
}

interface ImageBlockOptions {
  HTMLAttributes: Record<string, any>;
  src: string;
  type: ImageBlockTypes;
  width: string;
  align: "left" | "center" | "right";
  alt: string;
  documentId?: number;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imageBlock: {
      setImageBlock: (attributes: { src?: string }) => ReturnType;
      setLogoImageBlock: () => ReturnType;
      setProjectImageBlock: () => ReturnType;
      setImageBlockAt: (attributes: { src: string; pos: number | Range }) => ReturnType;
      setImageBlockAlign: (align: "left" | "center" | "right") => ReturnType;
      setImageBlockWidth: (width: number) => ReturnType;
      setImageSource: (src?: string, documentId?: number) => ReturnType;
    };
  }
}

export const ImageBlock = BaseImage.extend<ImageBlockOptions>({
  name: "imageBlock",

  group: "block",

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      src: {
        default: "",
        parseHTML: (element) => element.getAttribute("src"),
        renderHTML: (attributes) => ({
          src: attributes.src,
        }),
      },
      width: {
        default: "100%",
        parseHTML: (element) => element.getAttribute("data-width"),
        renderHTML: (attributes) => ({
          "data-width": attributes.width,
        }),
      },
      align: {
        default: "center",
        parseHTML: (element) => element.getAttribute("data-align"),
        renderHTML: (attributes) => ({
          "data-align": attributes.align,
        }),
      },
      type: {
        default: ImageBlockTypes.IMAGE,
      },
      alt: {
        default: undefined,
        parseHTML: (element) => element.getAttribute("alt"),
        renderHTML: (attributes) => ({
          alt: attributes.alt,
        }),
      },
      documentId: {
        default: undefined,
      },
    };
  },

  parseHTML() {
    // prevent importing images
    return [];
  },

  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setImageBlock:
        (attrs) =>
        ({ commands }) => {
          if (attrs.src) {
            return commands.insertContent({ type: "imageBlock", attrs: { src: attrs.src } });
          }
          return commands.insertContent({ type: "imageBlock" });
        },

      setLogoImageBlock:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: "imageBlock",
            attrs: {
              src: kImageLogoPlaceholder,
              type: ImageBlockTypes.LOGO,
              width: "50%",
            },
          });
        },
      setProjectImageBlock:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: "imageBlock",
            attrs: {
              src: kImageProjectPlaceholder,
              type: ImageBlockTypes.PROJECT_IMAGE,
              width: "100%",
            },
          });
        },

      setImageBlockAt:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContentAt(attrs.pos, { type: "imageBlock", attrs: { src: attrs.src } });
        },

      setImageBlockAlign:
        (align) =>
        ({ commands }) =>
          commands.updateAttributes("imageBlock", { align }),

      setImageBlockWidth:
        (width) =>
        ({ commands }) =>
          commands.updateAttributes("imageBlock", { width: `${Math.max(0, Math.min(100, width))}%` }),

      setImageSource:
        (src, documentId) =>
        ({ commands }) =>
          commands.updateAttributes("imageBlock", { src: src ?? "", documentId }),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageBlockView);
  },
});

export default ImageBlock;

/**
 * Change size of image while maintaining aspect ratio
 * - Quality defaults to 0.8
 */
export function createResizedFile(file: File, maxDimension: number, quality?: number): Promise<File> {
  return new Promise(async (resolve, reject) => {
    try {
      let img = new Image();

      img.onerror = (err) => {
        reject(err);
      };

      img.onload = () => {
        const oldWidth = img.width;
        const oldHeight = img.height;
        const maxVal = Math.max(oldWidth, oldHeight);

        let canvas = document.createElement("canvas");

        if (maxVal > maxDimension) {
          let newWidth: number, newHeight: number;

          if (oldWidth === oldHeight) {
            newHeight = maxDimension;
            newWidth = maxDimension;
          } else if (oldHeight > oldWidth) {
            newHeight = maxDimension;
            newWidth = Math.round((maxDimension * oldWidth) / oldHeight);
          } else {
            newHeight = Math.round((maxDimension * oldHeight) / oldWidth);
            newWidth = maxDimension;
          }

          // Create a temporary canvas to draw the downscaled image on.
          canvas.height = newHeight;
          canvas.width = newWidth;
          let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

          if (img.width === img.height) {
            ctx?.drawImage(img, 0, 0, maxDimension, maxDimension);
          } else {
            ctx?.drawImage(img, 0, 0, oldWidth, oldHeight, 0, 0, newWidth, newHeight);
          }
        } else {
          // Image is smaller than max size, no change
          canvas.height = oldHeight;
          canvas.width = oldWidth;
          let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

          ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
        }

        // Draw the downscaled image on the canvas and return the new data URL
        const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
        if (dataUrl === "data:,") {
          reject("Data Url Corrupted: " + dataUrl);
        }

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const now = Date.now();
              resolve(new File([blob], `${now}.jpg`, { type: "image/jpeg", lastModified: now }));
            } else {
              reject("Error creating resized blob file");
            }
          },
          "image/jpeg",
          quality ?? 0.9
        );
      };

      if (file) {
        img.src = await getFileDataUrl(file);
      } else {
        reject("No image path available");
      }
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * Converts a file to a data url
 * @param file
 * @returns
 */
function getFileDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

const Images = {
  createResizedFile,
};

export default Images;

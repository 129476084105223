import { memo, useEffect, useState } from "react";
import queryString from "query-string";

import { ArrowLeft, Check, LoaderCircle, SquareArrowOutUpRight } from "lucide-react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

import { useAppSelector, useAppDispatch } from "utils/store";

import { kToolbarHeight } from "models";
import { saveTemplate } from "utils/api";

const MemoButton = memo(Button);

export const TemplateToolbar = () => {
  const [showSave, setShowSave] = useState(false);
  const dispatch = useAppDispatch();
  const isSaving = useAppSelector((state) => state.templateBuilderSlice.isSaving);

  useEffect(() => {
    if (!isSaving && !showSave) {
      setShowSave(true);
      setTimeout(() => {
        setShowSave(false);
      }, 3000);
    }
  }, [isSaving]);

  const closeEditorCallback = () => {
    window.top?.postMessage(JSON.stringify({ action: "close" }), "*");
  };

  const openPreview = async () => {
    dispatch(saveTemplate()).then(() => {
      const params = queryString.parse(window.location.search);
      const tenantId = params.tid ?? "";
      const userId = params.uid ?? "";
      const templateId = params.rid ?? "";
      const token = params.token ?? "";
      window.open(`preview?tid=${tenantId}&rid=${templateId}&uid=${userId}&token=${token}`, "_blank");
    });
  };

  const start = (
    <div className="flex flex-row items-center gap-4">
      <MemoButton
        className="p-0"
        rounded
        severity="info"
        aria-label="Close Editor"
        tooltip="Close Editor"
        onClick={closeEditorCallback}
      >
        <div className="flex items-center justify-center" style={{ height: "42px", width: "42px" }}>
          <ArrowLeft />
        </div>
      </MemoButton>

      {/* <ReportTitleInput /> */}
      <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>Template Editor</span>
    </div>
  );

  const end = (
    <div className="flex flex-row items-center mr-4" style={{ color: "var(--green-800)" }}>
      {!isSaving && (
        <div
          className="inline-block mr-4"
          style={{ opacity: showSave && !isSaving ? 1 : 0, transition: "opacity 0.2s ease-in-out" }}
        >
          <div className="flex">
            <Check className="mr-1" size={20} strokeWidth={3} />
            <div className="mr-2">
              <b>Saved</b>
            </div>
          </div>
        </div>
      )}

      {isSaving && (
        <div className="inline-block mr-4">
          <div
            style={{
              animation: "spin 2s infinite linear",
              opacity: isSaving ? 1 : 0,
              transition: "opacity 0.1s ease-in-out",
            }}
          >
            <LoaderCircle size={30} color="var(--primary-200)" />
          </div>
        </div>
      )}

      <MemoButton
        label="Preview"
        tooltip="Preview Template"
        data-pr-position="bottom"
        aria-label="Preview Template"
        severity="info"
        className="mr-4"
        disabled={isSaving}
        onClick={openPreview}
      >
        <span className="ml-2"></span>
        <SquareArrowOutUpRight size={18} />
      </MemoButton>
    </div>
  );

  return <Toolbar className="p-0 pl-4" style={{ height: `${kToolbarHeight}px` }} start={start} end={end}></Toolbar>;
};

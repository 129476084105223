import { memo, useMemo } from "react";
import { Editor } from "@tiptap/react";

import { Plus } from "lucide-react";

import FIELD_TOKENS from "pages/tip-tap-editor/extensions/FieldToken/groups";
import { Toolbar } from "utils/ui";

const MemoButton = memo(Toolbar.Button);

export const FieldTokenPanel = ({ editor }: { editor: Editor }) => {
  const tokens = useMemo(() => {
    return FIELD_TOKENS.sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  return (
    <>
      {tokens.map((e) => {
        return (
          <MemoButton
            key={e.id}
            className="w-full"
            //   text
            //   tooltip={e.description}
            //   tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              editor.commands.insertContent({
                type: "mention",
                attrs: {
                  id: e.id,
                  label: e.label,
                },
              });
            }}
          >
            <div className="w-full flex flex-row items-center">
              <Plus size={16} />
              <span className="ml-2">{e.label}</span>
            </div>
          </MemoButton>
        );
      })}
    </>
  );
};

import { HTMLProps, forwardRef } from "react";

import { cn } from "utils/helpers";

import { Button, ButtonProps } from "primereact/button";

import { kToolbarHeight } from "models";
import { Tooltip } from "utils/ui";
import { SliderInput } from "../SliderInput";
import { ColorPickerButton } from "../ColorPickerButton";

const SidebarWrapper = forwardRef<HTMLDivElement, SidebarProps>(
  ({ title, shouldShowContent = true, children, className, style, ...rest }, ref) => {
    const toolbarClassName = cn("w-full", className);

    return (
      shouldShowContent && (
        <div className={toolbarClassName} {...rest} ref={ref}>
          <div
            className="p-4 overflow-y-auto classy-scrollbar"
            style={style ?? { height: `calc(100vh - 60px - 60px - ${kToolbarHeight}px)` }}
          >
            {children}
          </div>
        </div>
      )
    );
  }
);

SidebarWrapper.displayName = "SidebarMenu.Wrapper";

const Heading = forwardRef<HTMLDivElement, SidebarProps>(({ className, title, value, ...rest }, ref) => {
  const style = {};
  const dividerClassName = cn(className);

  return (
    <div className="flex flex-row mb-1">
      <h3 style={style} className={dividerClassName} ref={ref} {...rest}>
        {title}
      </h3>
      <span className="ml-2 text-sm" style={{ marginTop: "18px" }}>
        {value}
      </span>
    </div>
  );
});

Heading.displayName = "SidebarMenu.Heading";

const Divider = forwardRef<HTMLDivElement, SidebarProps>(({ className, ...rest }, ref) => {
  const style = { minWidth: "1.5rem", height: "1px" };
  const dividerClassName = cn("bg-neutral-200 mt-4 mb-4 w-full my-1", className);

  return <div style={style} className={dividerClassName} ref={ref} {...rest} />;
});

Divider.displayName = "SidebarMenu.Divider";

const SidebarButton = forwardRef<HTMLDivElement, SidebarButtonProps | any>(
  ({ className, active, tooltip, tooltipShortcut, ...rest }, ref) => {
    const dividerClassName = cn("p-button-info", className);

    return (
      <Tooltip title={tooltip} shortcut={tooltipShortcut} offset={-50}>
        <Button outlined={!active} className={dividerClassName} {...rest}>
          {rest.children}
        </Button>
      </Tooltip>
    );
  }
);

SidebarButton.displayName = "SidebarMenu.Button";

type SidebarProps = {
  title?: string;
  value?: number | string;
  shouldShowContent?: boolean;
} & HTMLProps<HTMLDivElement>;

type SidebarButtonProps = {
  title?: string;
  tooltip?: string;
  tooltipShortcut?: string[];
  onClick?: () => void;
  active?: boolean;
} & ButtonProps &
  HTMLProps<HTMLDivElement>;

export const SidebarMenu = {
  Wrapper: SidebarWrapper,
  Divider: Divider,
  Heading: Heading,
  Button: SidebarButton,
  SliderInput: SliderInput,
  ColorPicker: ColorPickerButton,
};

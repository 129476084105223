import { Content } from "@tiptap/react";
import { TDefectListOptions } from "./options.model";

export enum TemplatePageType {
  coverPage = "coverPage",
  contentsPage = "contentsPage",
  defectList = "defectList",
  customPage = "customPage",
}

const taskDataKey = Symbol("task");

export type TTemplatePageData = { [taskDataKey]: true; taskId: TSectionPage["id"] };

export function getTemplatePageData(task: TSectionPage): TTemplatePageData {
  return { [taskDataKey]: true, taskId: task.id };
}

// /** Assertion */
export function isTemplatePageData(data: Record<string | symbol, unknown>): data is TTemplatePageData {
  return data[taskDataKey] === true;
}

export type TTemplateContent = {
  /** Unique Azure Document ID */
  id: string;

  /** User friendly name */
  templateName: string;

  /** Version 1 Template Option Settings */
  defaultOptions: TDefectListOptions;

  /** Report pages */
  pages: TSectionPage[];

  /** Additional report settings */
  settings: {
    reportHeader: Content | undefined;
    reportFooter: Content | undefined;
  };
};

export type TSectionPage = {
  /** Unique ID for managing page position with Grabbable tool */
  id: string;

  /** Cover, Contents, Defect List, etc */
  type: TemplatePageType;

  /** Tiptap Editor content */
  content?: Content;
};

import "./LoadingController.scss";

import { memo } from "react";
import { BlockUI } from "primereact/blockui";
import { LoaderCircle } from "lucide-react";

const LoaderWidget = () => {
  return (
    <div className="loader-widget">
      <div className="spinner">
        <LoaderCircle size={38} color="var(--text-color)" />
      </div>
    </div>
  );
};

const AppLoadingController = ({ loading }: _LoadingControllerProps) => {
  return <BlockUI fullScreen={true} blocked={loading} template={<LoaderWidget />} />;
};

interface _LoadingControllerProps {
  loading: boolean;
}

export const LoadingController = memo(AppLoadingController);

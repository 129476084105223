import queryString from "query-string";
import axios from "axios";

import { IApiResponse, TTemplateContent } from "models";
import { API_ENDPOINT } from "utils/config";
import { Alert, Log } from "utils/services";

const redirectToRoot = () => {
  document.location.href = "/";
};

type HttpGetTemplateResponse = {
  readonly templateName: string;
  readonly lastModified: number;
  readonly templateId: number;
  readonly logoUrl: string;
  readonly storageToken: string;
  readonly items: TTemplateContent[];
};

export const getTemplate = async (): Promise<
  { templateName: string; logoUrl?: string; storageToken: string; data: TTemplateContent | undefined } | undefined
> => {
  let params = queryString.parse(window.location.search);
  const tenantId = params.tid ?? "";
  const templateId = params.rid ?? "";

  try {
    const request = await axios.get<IApiResponse<HttpGetTemplateResponse>>(API_ENDPOINT.REPORT_TEMPLATES, {
      params: {
        tenantId,
        templateId,
      },
    });

    console.log(request);

    if (request.status === 200 && request.data.success === true && request.data.data) {
      let reportData: TTemplateContent | undefined;

      if (Array.isArray(request.data.data.items) && request.data.data.items.length > 0) {
        // always first item in array.
        // array should be single length
        reportData = request.data.data.items[0];
      }

      return {
        templateName: request.data.data.templateName,
        logoUrl: request.data.data.logoUrl,
        storageToken: request.data.data.storageToken,
        data: reportData,
      };
    } else if (request.data.success !== true) {
      Alert.simpleAlert({
        header: `Error: ${request.status}`,
        message: request.data.message ?? `An unknown error occurred, message: ${request.statusText}`,
        callbackFn: redirectToRoot,
      });
    }
  } catch (ex) {
    Log.handleException(ex, {
      hint: {
        endpoint: "getTemplate()",
        params: { tenantId, templateId },
      },
      showAlert: true,
      alertCallbackFn: redirectToRoot,
    });
  }

  return;
};

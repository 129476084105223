import { mergeAttributes, Node, NodeViewProps } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";

// https://tiptap.dev/docs/editor/extensions/custom-extensions/node-views/react

export interface HorizontalLineBlockOptions {
  HTMLAttributes: Record<string, any>;
  color: string;
  height: number;
  width: number;
  alignment: "left" | "center" | "right";
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    horizontalLine: {
      setHorizontalLine: () => ReturnType;
      setHorizontalLineWidth: (width: number) => ReturnType;
      setHorizontalLineHeight: (height: number) => ReturnType;
      setHorizontalLineAlign: (alignment: "left" | "center" | "right") => ReturnType;
      setHorizontalLineColor: (color: string) => ReturnType;
    };
  }
}

const HorizontalLineComponent = ({ node }: NodeViewProps) => {
  const { color, height, width } = node.attrs;
  let alignment: string = "";
  switch (node.attrs.alignment) {
    case "center":
      alignment = "justify-center";
      break;
    case "right":
      alignment = "justify-end";
      break;
    case "left":
    default:
      alignment = "justify-start";
      break;
  }

  return (
    <NodeViewWrapper className="horizontal-line-block">
      <div className={`w-full flex items-center ${alignment}`} style={{ minHeight: "22px" }}>
        <div style={{ backgroundColor: color, height: `calc(${height}px * var(--editor-scale)`, width: `${width}%` }} />
      </div>
    </NodeViewWrapper>
  );
};

export const HorizontalLineBlock = Node.create<HorizontalLineBlockOptions>({
  name: "horizontalLine",

  group: "block",

  content: "",

  selectable: true,

  // this for, @Mentions, project photo, not text stuff
  atom: true,

  addAttributes() {
    return {
      color: {
        default: "#000000",
      },
      height: {
        default: 5,
      },
      width: {
        default: 100,
      },
      alignment: {
        default: "center",
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(HorizontalLineComponent);
  },

  addCommands() {
    return {
      setHorizontalLine:
        () =>
        ({ commands }) => {
          return commands.insertContent({ type: "horizontalLine" });
        },
      setHorizontalLineWidth:
        (width) =>
        ({ commands }) => {
          return commands.updateAttributes("horizontalLine", { width });
        },
      setHorizontalLineHeight:
        (height) =>
        ({ commands }) => {
          return commands.updateAttributes("horizontalLine", { height });
        },
      setHorizontalLineAlign:
        (alignment) =>
        ({ commands }) => {
          return commands.updateAttributes("horizontalLine", { alignment });
        },
      setHorizontalLineColor:
        (color) =>
        ({ commands }) => {
          return commands.updateAttributes("horizontalLine", { color });
        },
    };
  },
});

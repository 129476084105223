import {
  CharacterCount,
  Color,
  Document,
  Dropcursor,
  Figcaption,
  Focus,
  Heading,
  ImageBlock,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  Columns,
  Column,
  SignatureBlock,
  HorizontalLineBlock,
  FieldToken,
  SpacerBlock,
  PageBreakBlock,
} from ".";

export const ExtensionKit = [
  Document,
  Columns,
  Column,
  Selection,
  Heading.configure({
    HTMLAttributes: {
      class: "report-heading",
    },
    levels: [1, 2, 3],
  }),
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    blockquote: false,
    codeBlock: false,
    paragraph: {
      HTMLAttributes: {
        class: "report-paragraph",
      },
    },
  }),
  TextStyle,
  Color,
  TrailingNode,
  Link.configure({
    openOnClick: false,
  }),
  Underline,
  CharacterCount.configure({ limit: 50000 }),
  ImageBlock,
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {};
    },
  }).configure({
    types: ["heading", "paragraph"],
  }),
  Typography,
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: () => "",
  }),
  SlashCommand,
  Focus,
  Figcaption,
  Dropcursor.configure({
    width: 2,
    class: "ProseMirror-dropcursor border-black",
  }),
  // My custom blocks
  SignatureBlock,
  HorizontalLineBlock,
  FieldToken,
  SpacerBlock,
  PageBreakBlock,
  //
  // Table,
  // TableCell,
  // TableHeader,
  // TableRow,
  // TaskList,
  // TaskItem.configure({
  //   nested: true,
  // }),
  // ImageUpload.configure({
  //   clientId: provider?.document?.clientID,
  // }),
  // HorizontalRule,
];

export default ExtensionKit;

import "./custom-page-template.scss";
import { useDebouncedCallback } from "use-debounce";

import { Tiptap } from "pages/tip-tap-editor";

import { kToolbarHeight } from "models";
import { saveTemplate } from "utils/api";
import { useAppSelector, useAppDispatch, onContentChange } from "utils/store";

export const CustomPageTemplate = () => {
  const dispatch = useAppDispatch();

  /** Initial state data for editor */
  const editorInit = useAppSelector((state) => state.templateBuilderSlice.editorInit);

  const doDebouncedSave = useDebouncedCallback(() => dispatch(saveTemplate()), 3000, {
    leading: false,
    trailing: true,
  });

  const onChange = async (json: any): Promise<void> => {
    dispatch(onContentChange(json));
    doDebouncedSave();
  };

  const onBlur = async (): Promise<void> => doDebouncedSave();

  return <Tiptap toolbarHeight={kToolbarHeight} initialData={editorInit} onChange={onChange} onBlur={onBlur} />;
};

import { memo, useCallback, useRef } from "react";
import { BubbleMenu as BaseBubbleMenu, Editor, useEditorState } from "@tiptap/react";

import { Instance, sticky } from "tippy.js";
import { v4 as uuidv4 } from "uuid";
import deepEql from "fast-deep-equal";

import { ColorPicker } from "../../panels";
import { Toolbar, Icon, Surface, Tooltip } from "utils/ui";

import { MenuProps } from "../types";
import { getRenderContainer } from "../../../utils";
import * as Popover from "@radix-ui/react-popover";

import { HorizontalLineBlockHeight } from "./components/HorizontalLineHeight";
import { HorizontalLineBlockWidth } from "./components/HorizontalLineWidth";

const MemoButton = memo(Toolbar.Button);
const MemoColorPicker = memo(ColorPicker);

export const HorizontaslLineBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const tippyInstance = useRef<Instance | null>(null);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, "node-horizontalLine");
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive("horizontalLine");

    return isActive;
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`horizontalLineBlockMenu-${uuidv4()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: "flip", enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: "popper",
      }}
    >
      <div ref={menuRef}>
        <HorizontalLineMenu editor={editor} />
      </div>
    </BaseBubbleMenu>
  );
};

export const HorizontalLineMenu = ({ editor }: { editor: Editor }) => {
  const { isImageCenter, isImageLeft, isImageRight, width, height, currentColor } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isImageLeft: ctx.editor.isActive("horizontalLine", { alignment: "left" }),
        isImageCenter: ctx.editor.isActive("horizontalLine", { alignment: "center" }),
        isImageRight: ctx.editor.isActive("horizontalLine", { alignment: "right" }),
        width: parseInt(ctx.editor.getAttributes("horizontalLine")?.width || 0),
        height: parseInt(ctx.editor.getAttributes("horizontalLine")?.height || 0),
        currentColor: ctx.editor.getAttributes("horizontalLine")?.color || undefined,
      };
    },
    equalityFn: deepEql,
  });

  const onAlignLeft = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineAlign("left").run();
  }, [editor]);

  const onAlignCenter = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineAlign("center").run();
  }, [editor]);

  const onAlignRight = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineAlign("right").run();
  }, [editor]);

  const onWidthChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineWidth(value).run();
    },
    [editor]
  );

  const onHeightChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineHeight(value).run();
    },
    [editor]
  );

  const onChangeColor = useCallback((color: string) => editor.chain().setHorizontalLineColor(color).run(), [editor]);

  const onDelete = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).deleteSelection().run();
  }, [editor]);

  return (
    <Toolbar.Wrapper
      // shouldShowContent={shouldShow()}
      // ref={menuRef}
      title={"Horizontal Line"}
    >
      <Toolbar.Button className="p-4" tooltip="Align left" active={isImageLeft} onClick={onAlignLeft}>
        <Icon name="AlignHorizontalDistributeStart" />
      </Toolbar.Button>
      <Toolbar.Button tooltip="Align center" active={isImageCenter} onClick={onAlignCenter}>
        <Icon name="AlignHorizontalDistributeCenter" />
      </Toolbar.Button>
      <Toolbar.Button tooltip="Align right" active={isImageRight} onClick={onAlignRight}>
        <Icon name="AlignHorizontalDistributeEnd" />
      </Toolbar.Button>
      <Toolbar.Divider />
      <HorizontalLineBlockWidth onChange={onWidthChange} value={width} />
      <Toolbar.Divider />
      <HorizontalLineBlockHeight onChange={onHeightChange} value={height} />
      <Toolbar.Divider />
      {/** COLOR PICKER */}
      <Popover.Root>
        <Popover.Trigger asChild>
          <MemoButton active={!!currentColor} tooltip="Line color">
            <Icon name="Palette" />
          </MemoButton>
        </Popover.Trigger>
        <Popover.Content side="top" sideOffset={8} asChild>
          <Surface className="p-1">
            <MemoColorPicker
              color={currentColor}
              onChange={onChangeColor}
              onClear={() => {
                onChangeColor("#000000");
              }}
            />
          </Surface>
        </Popover.Content>
      </Popover.Root>

      <Toolbar.Divider />

      <Tooltip title="Remove line">
        <Toolbar.Button onClick={onDelete}>
          <Icon name="Trash2" />
        </Toolbar.Button>
      </Tooltip>
    </Toolbar.Wrapper>
  );
};

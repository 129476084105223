import { Mention } from "@tiptap/extension-mention";
import suggestion from "./suggestion";
import { mergeAttributes } from "@tiptap/react";

export const FieldToken = Mention.configure({
  HTMLAttributes: {
    class: "field-token",
  },
  renderText({ options, node }) {
    return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
  },
  renderHTML({ options, node }) {
    return [
      "span",
      mergeAttributes({ "token-field-id": node.attrs.id }, options.HTMLAttributes),
      `[${node.attrs.label ?? node.attrs.id}]`,
    ];
  },
  suggestion,
});

export default FieldToken;

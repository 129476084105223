import { SettingsKey } from "./settings.model";

export enum IDefectBlockOrientation {
  standard = "standard",
  inline = "inline",
  below = "below",
  noPhotos = "noPhotos",
}

type TReportStringOption = {
  readonly key: SettingsKey;
  value: string | IDefectBlockOrientation;
  readonly datatype: "string";
};

type TReportBoolOption = {
  readonly key: SettingsKey;
  value: boolean;
  readonly datatype: "boolean";
};

type TReportNumberOption = {
  readonly key: SettingsKey;
  value: number;
  readonly datatype: "int";
};

export type TDefectListOption = TReportStringOption | TReportBoolOption | TReportNumberOption;

export type TDefectListOptions = {
  [key in SettingsKey]?: TDefectListOption;
};

/**
 * Maintains list of settings related to reports
 */
export const blankDefectListOptions: TDefectListOptions = {
  [SettingsKey.ReportShowDefectOrientation]: {
    key: SettingsKey.ReportShowDefectOrientation,
    value: IDefectBlockOrientation.standard,
    datatype: "string",
  },

  [SettingsKey.ReportShowDefectPhotoMeta]: {
    key: SettingsKey.ReportShowDefectPhotoMeta,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectNumber]: {
    key: SettingsKey.ReportShowDefectNumber,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectStatus]: {
    key: SettingsKey.ReportShowDefectStatus,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectPriority]: {
    key: SettingsKey.ReportShowDefectPriority,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectDuedate]: {
    key: SettingsKey.ReportShowDefectDuedate,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectArea]: {
    key: SettingsKey.ReportShowDefectArea,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectElement]: {
    key: SettingsKey.ReportShowDefectElement,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectIssue]: {
    key: SettingsKey.ReportShowDefectIssue,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectAssignment]: {
    key: SettingsKey.ReportShowDefectAssignment,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectComments]: {
    key: SettingsKey.ReportShowDefectComments,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ReportShowDefectActivity]: {
    key: SettingsKey.ReportShowDefectActivity,
    value: true,
    datatype: "boolean",
  },

  [SettingsKey.ContentsPageTitle]: {
    key: SettingsKey.ContentsPageTitle,
    value: "Contents",
    datatype: "string",
  },
};

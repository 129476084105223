import queryString from "query-string";
import axios from "axios";

import { IApiResponse, TDefectListOptions, TSectionPage } from "models";
import { API_ENDPOINT } from "utils/config";
import { Alert, Log } from "utils/services";

export const postTemplate = async (
  templateName: string,
  data: {
    /** IF undefined, this is a new document  */
    id: string | undefined;
    pages: TSectionPage[];
    defaultOptions: TDefectListOptions;
    settings: any;
    version: number;
  }
): Promise<string | undefined> => {
  let params = queryString.parse(window.location.search);
  const tenantId = params.tid ?? "";
  const templateId = params.rid ?? "";

  try {
    const request = await axios.put<
      IApiResponse<{
        /** Returns azure doc id if needed */
        items: string[];
        templateName: string;
        lastModified: number;
        templateId: number;
      }>
    >(API_ENDPOINT.REPORT_TEMPLATES, {
      tenantId,
      templateId,
      templateName,
      items: [data],
    });
    // console.log(request);

    if (
      request.data?.success === true &&
      Array.isArray(request.data.data?.items) &&
      request.data.data?.items.length > 0
    ) {
      const documentId = request.data.data?.items[0];
      return documentId;
    } else {
      Alert.simpleAlert({
        header: `Error: ${request.status}`,
        message: request.data.message ?? `An unknown error occurred, message: ${request.statusText}`,
      });
    }
    //
  } catch (ex) {
    Log.handleException(ex, {
      hint: {
        endpoint: "saveTemplate()",
        params: { tenantId, templateId },
      },
      showAlert: true,
    });
  }

  return;
};

import { useCallback, memo } from "react";
import { Editor } from "@tiptap/react";

import { ButtonGroup } from "primereact/buttongroup";
import { Button } from "primereact/button";

import { Icon } from "utils/ui";
import { ZoomController } from "../SideMenuZoomController";

const MemoZoonController = memo(ZoomController);
const MemoButton = memo(Button);

export const SidebarMenuFooter = ({ editor }: { editor: Editor }) => {
  const onUndo = useCallback(() => {
    editor.commands.undo();
  }, [editor]);

  const onRedo = useCallback(() => {
    editor.commands.redo();
  }, [editor]);

  return (
    <div
      className="pl-4 pr-4 text-center surface-section"
      style={{ bottom: 0, position: "absolute", width: "100%", height: "60px" }}
    >
      <div className="h-full flex flex-row justify-start items-center">
        <ButtonGroup>
          <MemoButton
            title="Undo"
            className="p-3 p-button-info"
            outlined
            onClick={onUndo}
            children={<Icon name="Undo" size={16} />}
          />
          <MemoButton
            title="Redo"
            className="p-3 p-button-info"
            outlined
            onClick={onRedo}
            children={<Icon name="Redo" size={16} />}
          />
        </ButtonGroup>

        <div className="p-2" />

        <MemoZoonController />
      </div>
    </div>
  );
};

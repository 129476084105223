import { useEditor } from "@tiptap/react";
import type { AnyExtension } from "@tiptap/core";

import { ExtensionKit } from "../extensions/extension-kit";

// https://tiptap.dev/docs/guides/performance
export const useBlockEditor = ({ onChange, onBlur }: { onChange: (json: any) => void; onBlur: () => void }) => {
  const editor = useEditor(
    {
      immediatelyRender: true,
      shouldRerenderOnTransaction: false,
      autofocus: true,
      onCreate: (ctx) => {
        ctx.editor.commands.focus("start", { scrollIntoView: true });
      },
      /**
       * Triggered on every change
       *
       * Update local state
       */
      onUpdate: ({ editor }) => {
        const json = editor.getJSON();
        onChange(json);
      },
      /** Trigger API save */
      onBlur() {
        onBlur();
      },
      extensions: [...ExtensionKit].filter((e): e is AnyExtension => e !== undefined),
      editorProps: {
        attributes: {
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off",
          class: "",
        },
      },
      onContentError({ editor, error }) {
        // Your error handling logic here
        console.error(error);
      },
      // https://tiptap.dev/docs/guides/invalid-schema
      enableContentCheck: true,
      // This option gives us the control to enable the default behavior of rendering the editor immediately.
      // immediatelyRender: true,
      // This option gives us the control to disable the default behavior of re-rendering the editor on every transaction.
      // shouldRerenderOnTransaction: false,
    },
    []
  );

  return { editor };
};

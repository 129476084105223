import React, { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "@tiptap/core";
import { TokenCommand } from "./types";
import { Surface, DropdownButton } from "utils/ui";

interface _MenuListProps {
  editor: Editor;
  items: TokenCommand[];
  command: (command: TokenCommand) => void;
}

export const TokenMenuList = React.forwardRef((props: _MenuListProps, ref) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const activeItem = useRef<HTMLButtonElement>(null);
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);

  // Anytime the groups change, i.e. the user types to narrow it down, we want to
  // reset the current selection to the first menu item
  useEffect(() => {
    setSelectedCommandIndex(0);
  }, [props.items]);

  const selectItem = useCallback(
    (commandIndex: number) => {
      const command = props.items[commandIndex];
      props.command(command);
    },
    [props]
  );

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === "ArrowDown") {
        if (!props.items.length) {
          return false;
        }

        const commands = props.items;

        let newCommandIndex = selectedCommandIndex + 1;

        if (commands.length - 1 < newCommandIndex) {
          newCommandIndex = 0;
        }

        setSelectedCommandIndex(newCommandIndex);

        return true;
      }

      if (event.key === "ArrowUp") {
        if (!props.items.length) {
          return false;
        }

        let newCommandIndex = selectedCommandIndex - 1;

        if (newCommandIndex < 0) {
          newCommandIndex = props.items.length - 1 || 0;
        }

        setSelectedCommandIndex(newCommandIndex);

        return true;
      }

      if (event.key === "Enter") {
        if (!props.items.length || selectedCommandIndex === -1) {
          return false;
        }

        selectItem(selectedCommandIndex);

        return true;
      }

      return false;
    },
  }));

  useEffect(() => {
    if (activeItem.current && scrollContainer.current) {
      const offsetTop = activeItem.current.offsetTop;
      const offsetHeight = activeItem.current.offsetHeight;

      scrollContainer.current.scrollTop = offsetTop - offsetHeight;
    }
  }, [selectedCommandIndex]);

  const createCommandClickHandler = useCallback(
    (commandIndex: number) => {
      return () => {
        selectItem(commandIndex);
      };
    },
    [selectItem]
  );

  if (!props.items.length) {
    return null;
  }

  return (
    <Surface ref={scrollContainer} className="text-black max-h-[min(80vh,24rem)] overflow-auto flex-wrap mb-8 p-2">
      <div className="grid grid-cols-1 gap-0-5">
        {props.items.length ? (
          props.items.map((command: TokenCommand, commandIndex: number) => (
            <DropdownButton
              key={`${command.label}`}
              ref={selectedCommandIndex === commandIndex ? activeItem : null}
              isActive={selectedCommandIndex === commandIndex}
              onClick={createCommandClickHandler(commandIndex)}
            >
              {command.label}
            </DropdownButton>
          ))
        ) : (
          <div className="item">No result</div>
        )}
      </div>
    </Surface>
  );
});

TokenMenuList.displayName = "TokenMenuList";

export default TokenMenuList;
